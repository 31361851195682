import * as React from 'react'
import { Link as NavLink, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Box, Link, Container, Typography } from '@mui/material';
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image';
import kebabCase from "lodash/kebabCase"

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={data.mdx.frontmatter.meta.title}
        description={data.mdx.frontmatter.meta.description}
        imageUrl={data.mdx.frontmatter.featuredImage?.childImageSharp?.fixed?.src}
        canonicalUrl={data.mdx.frontmatter.canonicalUrl}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h1">{data.mdx.frontmatter.title}</Typography>
        <Typography variant="subtitle1">{data.mdx.timeToRead} min read &#183; Last updated {data.mdx.frontmatter.lastUpdatedDate}</Typography>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
          <Box>
            {data.mdx.frontmatter.categories.map(category => (
              <Link component={NavLink} underline="hover" key={category} to={`/category/${kebabCase(category)}/`} sx={{ mr: 1 }}>{category}</Link>
            ))}
          </Box>
          {data.mdx.frontmatter.tags.length > 0 && (
            <Box>
              {data.mdx.frontmatter.tags.map(tag => (
                <Link component={NavLink} underline="hover" key={tag} to={`/tag/${kebabCase(tag)}/`} sx={{ ml: { xs: 0, md: 1 }, mr: { xs: 1, md: 0 } }}>#{tag}</Link>
              ))}
            </Box>
          )}

        </Box>
        {data.mdx.frontmatter.featuredImage && (<GatsbyImage
          image={data.mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          placeholder="blurred"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt={data.mdx.frontmatter.title}
          style={{ borderRadius: '8px', width: '100%' }}
        />)}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ width: { xs: '100%', md: '75%' }, "> p": { marginBottom: '45px' } }}>
            <MDXRenderer localImages={data.mdx.frontmatter.embeddedImagesLocal} tableOfContents={data.mdx.tableOfContents} products={data.mdx.frontmatter.products}>
              {data.mdx.body}
            </MDXRenderer>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '25%', textAlign: 'center' }, pl: 2, pt: 5 }}>
            {data.mdx.frontmatter.sidebarImages &&
              data.mdx.frontmatter.sidebarImages.map((image, i) => (
                <Link key={"link-"+i} href={data.mdx.frontmatter.sidebarLinks[i]} target="blank" sx={{pt: 2}}>
                  <GatsbyImage image={image.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    style={{ width: '100%', marginTop: '25px'}}
                    alt="" />
                </Link>
              ))}

              {data.mdx.frontmatter.sidebarAdImages &&
              data.mdx.frontmatter.sidebarAdImages.map((adImage, i) => (
                <Link key={"adLink-"+i} href={data.mdx.frontmatter.sidebarAdLinks[i]} target="blank" sx={{pt: 2}}>
                  <img src={adImage}
                    style={{ marginTop: '25px' }}
                    alt="" />
                </Link>
              ))}
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      meta {
        title
        description
      }
      canonicalUrl
      lastUpdatedDate(formatString: "MMMM D, YYYY")
      categories
      tags
      featuredImage {
        childImageSharp {
          fixed(width: 600) {
            src
          }
          gatsbyImageData
        }
      }
      embeddedImagesLocal {
        childImageSharp {
          gatsbyImageData
        }
      }
      sidebarImages {
        childImageSharp {
          gatsbyImageData
        }
      }
      sidebarLinks
      sidebarAdImages
      sidebarAdLinks
    }
    tableOfContents
    body
    timeToRead
  }
}
`
export default BlogPost